import ReactPlayer from "react-player";
import "../../../App.css";
import {
  Container,
  Card,
  Row,
  Col,
  h5,
  p,
  h2,
  h3,
  div,
  Alert,
  Modal,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Typography from "@mui/material/Typography";

export const OrderSlider = () => {
  return (
    <div className="OrderSlider">
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Order Now: Browse, view, and track your order.
          </title>
          <link rel="canonical" href="/menu" />
          <meta
            name="description"
            content="Where Every Bite Ignites Your Delight! this eatery promises a dining experience like no other."
          />
        </Helmet>
      </HelmetProvider>
      <div className="OrderHeader">
        <Container>
          <Row>
            <col className="whitespace"></col>
            <Col sm={12} lg={8}>
              <h1 className="left gold shadow">Promises a dining experience like no other</h1>
              <h3 className="white">
                {" "}
                Each dish is carefully crafted to be a work of art, a true grilling extravaganza where the flavors are set ablaze, creating a symphony for the taste buds.
              </h3>
              {/* <h2 className="left gold whitespace">
                Wheat-free, gluten-free, sugar-free, & soy-free.
              </h2>
              <Typography variant="body1" className="white">
                {" "}
                A particular focus is given to low or no 'carb' items, creating
                preservative-free offerings, made fresh daily, and truly
                satisfying.
              </Typography> */}
            </Col>
            <Col sm={0} lg={4}>
              {/*<Card>
        <Card.Img src="/static/1.jpg" />
    </Card> */}
            </Col>
            <col className="whitespace"></col>
          </Row>
        </Container>
      </div>
    </div>
  );
};