import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export default function PhotoGalleryList() {
  return (
    <ImageList
      // sx={{ width: 500, height: 450 }}
      wrapperClass="wrapper"
      gridClass="col-md-4"
      variant="quilted"
      imgClass="responsive"
      cols={4}
    // rowHeight={150}
    >
      {itemData.map((item) => (
        <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
          <img
            {...srcset(item.img, item.rows, item.cols)}
            alt={item.title}
            loading="lazy"
            title={item.title}
            width="100%"
            height="100%"
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

const itemData = [
  {
    img: '/static/1.jpg',
    title: 'ABC Grill Restaurant & Catering - Restaurant Image001',
  },
  {
    img: '/static/2.jpg',
    title: 'ABC Grill Restaurant & Catering - Restaurant Image002',
  },
  {
    img: '/static/3.jpg',
    title: 'ABC Grill Restaurant & Catering - Restaurant Image003',
  },
  {
    img: '/static/4.jpg',
    title: 'ABC Grill Restaurant & Catering - Restaurant Image004',
  },
  {
    img: '/static/5.jpg',
    title: 'ABC Grill Restaurant & Catering - Restaurant Image005',
  },
  {
    img: '/static/6.jpg',
    title: 'ABC Grill Restaurant & Catering - Restaurant Image006',
    author: '@arwinneil',
  },
  {
    img: '/static/7.jpg',
    title: 'ABC Grill Restaurant & Catering - Restaurant Image007',
  },
  {
    img: '/static/8.jpg',
    title: 'ABC Grill Restaurant & Catering - Restaurant Image008',
  },
  {
    img: '/static/9.jpg',
    title: 'ABC Grill Restaurant & Catering - Restaurant Image009',
  },
  {
    img: '/static/10.jpg',
    title: 'ABC Grill Restaurant & Catering - Restaurant Image010',
  },
  {
    img: '/static/11.jpg',
    title: 'ABC Grill Restaurant & Catering - Restaurant Image011',
  },
  {
    img: '/static/12.jpg',
    title: 'ABC Grill Restaurant & Catering - Restaurant Image012',
  },
  {
    img: '/static/13.jpg',
    title: 'ABC Grill Restaurant & Catering - Restaurant Image013',
  },
  {
    img: '/static/14.jpg',
    title: 'ABC Grill Restaurant & Catering - Restaurant Image014',
  },
  {
    img: '/static/15.jpg',
    title: 'ABC Grill Restaurant & Catering - Restaurant Image015',
  },
  {
    img: '/static/16.jpg',
    title: 'ABC Grill Restaurant & Catering - Restaurant Image016',
  },
  {
    img: '/static/17.jpg',
    title: 'ABC Grill Restaurant & Catering - Restaurant Image017',
  },
  {
    img: '/static/18.jpg',
    title: 'ABC Grill Restaurant & Catering - Restaurant Image018',
  },
  {
    img: '/static/19.jpg',
    title: 'ABC Grill Restaurant & Catering - Restaurant Image019',
  },
  {
    img: '/static/20.jpg',
    title: 'ABC Grill Restaurant & Catering - Restaurant Image020',
  },
];
