import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";

// ================ THIS FORM WILL ONLY WORK IF WRAPPED in FormProvider by react-hook-form ==================
const BaseInput = ({
  name,
  label,
  type = "text",
  variant = "outlined",
  helperText,
  required = false,
  disabled = false,
  customError,
  customizer = {},
}) => {
  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext();
  const error = customError ? customError : errors[name];
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={getValues(name)}
      render={({ field }) => (
        <TextField
          {...field} // React hook form control props
          {...customizer} // Add MUI custom props
          label={label ? `${label} ${required ? "*" : ""}` : ""}
          type={type}
          variant={variant}
          error={!!error}
          helperText={error ? error?.message : helperText}
          disabled={disabled}
        />
      )}
    />
  );
};

BaseInput.prototype = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.oneOf[("text", "number", "date")],
  variant: PropTypes.oneOf[("filled", "outlined", "standard")],
  helperText: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  customError: PropTypes.func,
  customizer: PropTypes.object,
};

export default BaseInput;
