/*import "../../../../../App.css";*/
import React from "react";
import "../../../../../assets/css/custom.css";
import {
  Container,
  Col,
} from "react-bootstrap";
import { FaInstagramSquare, FaFacebookF, FaTwitterSquare } from "react-icons/fa";
import { ImYoutube2 } from "react-icons/im";
import Typography from '@mui/material/Typography';

export const SocialConnect = () => {
  return (
    <div className="social-connect">
      <Container className="social-icons ">
        <Col xs={12} className="social-title">
          <Typography variant="h4" gutterBottom component="div">
            Social Connect
          </Typography>
        </Col>
        <Col xs={12} className="social-icons icon">
          <ul class="social-tags">
            <li>
              <a href="https://www.facebook.com/ajsketofactory" target="_blank" alt="Follow us on Facebook" aria-hidden="true">
                <i class="fab fa-facebook-f"><FaFacebookF /></i>
              </a>
            </li>
            <li>
              <a href="https://www.twitter.com/ajsketofactory" target="_blank" alt="Follow us on Twitter" aria-hidden="true">
                <i class="fab fa-twitter"><FaTwitterSquare /></i>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/ajsketofactory" target="_blank" alt="Follow us on Instagram" aria-hidden="true">
                <i class="fab fa-instagram"><FaInstagramSquare /></i>
              </a>
            </li>
          </ul>
        </Col>
      </Container>
    </div>
  );
};