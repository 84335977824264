import { CarouselItem, CarouselCaption, Carousel, img } from "react-bootstrap";
import Slider1 from "../../../../../assets/images/header_img_1.jpg";
import Slider2 from "../../../../../assets/images/header_img_2.jpg";
import Slider3 from "../../../../../assets/images/header_img_3.jpg";
import Slider4 from "../../../../../assets/images/header_img_4.jpg";
import Slider5 from "../../../../../assets/images/header_img_5.jpg";
import React, { useEffect } from 'react'

export const HomepageSlider = () => {

  useEffect(() => {
    document.title = "Where Every Bite Ignites Your Delight!"
  }, [])

  return (
    <Carousel fade>
      <Carousel.Item>
        <img width="100%" height="100%" title="Sizzle & Share" className="d-block w-100" src={Slider1} alt="Indulge in Flavorful Moments at ABC Grill Restaurant & Catering - Where Every Bite's a Delight!" loading="lazy" />
        <Carousel.Caption className="d-flex h-100 align-items-center justify-content-center">
          <div className="slider-caption">
            <h1>Sizzle & Share</h1>
            <p>Indulge in Flavorful Moments at ABC Grill Restaurant & Catering - Where Every Bite's a Delight!</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img width="100%" height="100%" title="Grill Masters Unleashed" className="d-block w-100" src={Slider2} alt="Join Us for a Carnivore's Paradise at ABC Grill Restaurant & Catering - Where We Grill, You Chill!" loading="lazy" />
        <Carousel.Caption className="d-flex h-100 align-items-center justify-content-center">
          <div className="slider-caption">
            <h1>Grill Masters Unleashed</h1>
            <p>Join Us for a Carnivore's Paradise at ABC Grill Restaurant & Catering - Where We Grill, You Chill!</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img width="100%" height="100%" title="Fire & Feast Fiesta" className="d-block w-100" src={Slider3} alt="Experience the Ultimate Grilling Extravaganza at ABC Grill Restaurant & Catering - Every Plate's a Work of Art!" loading="lazy" />
        <Carousel.Caption className="d-flex h-100 align-items-center justify-content-center">
          <div className="slider-caption">
            <h1>Fire & Feast Fiesta</h1>
            <p>Experience the Ultimate Grilling Extravaganza at ABC Grill Restaurant & Catering - Every Plate's a Work of Art!</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img width="100%" height="100%" title="Smoke & Savor Spectacle" className="d-block w-100" src={Slider4} alt="Ignite Your Taste Buds at ABC Grill Restaurant & Catering - Where BBQ Dreams Come True!" loading="lazy" />
        <Carousel.Caption className="d-flex h-100 align-items-center justify-content-center">
          <div className="slider-caption">
            <h1>Smoke & Savor Spectacle</h1>
            <p>Ignite Your Taste Buds at ABC Grill Restaurant & Catering - Where BBQ Dreams Come True!</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img width="100%" height="100%" title="Flames & Friends Gathering" className="d-block w-100" src={Slider5} alt="Crafting Memorable Moments, One Grill at a Time - Join the ABC Grill Restaurant & Catering Family!" loading="lazy" />
        <Carousel.Caption className="d-flex h-100 align-items-center justify-content-center">
          <div className="slider-caption">
            <h1>Flames & Friends Gathering</h1>
            <p>Crafting Memorable Moments, One Grill at a Time - Join the ABC Grill Restaurant & Catering Family!</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};
