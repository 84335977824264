import "../../../App.css";
import {
  Container,
  Row,
  Col,
  Button,
  h5,
  h1,
  h6,
  p,
  h2,
  div,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactPlayer from "react-player";
// import center from '../../../assets/css/custom.css';
import { MenuNavigation } from "../components/MenuNavigation";
import { Footer } from "../components/Footer";
import aboutimg1 from "../../../assets/images/about-img-01.jpg";
import aboutimg2 from "../../../assets/images/about-img-02.jpg";
import aboutimg3 from "../../../assets/images/about-img-03.jpg";
import LovingAllPeople from "../../../assets/images/loving-all-people-icon.png";
import CreatingSuperheroes from "../../../assets/images/creating-superheroes-icon.png";
import ExplodingTheBox from "../../../assets/images/exploding-the-box-icon.png";
import ImpactingTheWorld from "../../../assets/images/impacting-the-world-icon.png";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { withRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Typography from "@mui/material/Typography";

export const About = () => {
  useEffect(() => {
    document.title = "Where Every Bite Ignites Your Delight!";
    // ReactGA.initialize("UA-172332025-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="About">
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Where Every Bite Ignites Your Delight!</title>
          <link rel="canonical" href="/about" />
          <meta
            name="description"
            content="Encapsulates the restaurant's commitment to crafting memorable moments through exceptional flavors, turning each meal into a feast that leaves patrons with a lasting, fiery delight."
          />
        </Helmet>
      </HelmetProvider>
      <MenuNavigation />
      <div className="HeaderImg about-info">
        <Container>
          <Row>
            <col className="whitespace"></col>
            <Col xs={8}>
              <Typography variant="body1" className="white">
                Purpose: To kindle moments of culinary delight and connection.{" "}
              </Typography>
              <br />
              <h1 className="gold shadow">
                Igniting the passion
                <br></br>
                for exceptional grilling
              </h1>
            </Col>
            <Col xs={8}>
              <Typography variant="body1" className="white">
                {" "}
                Through the art of the grill, we strive to create a <strong>warm and welcoming atmosphere</strong> where flavors come alive, and every bite is an invitation to savor life's finest moments.
              </Typography>
              <br />
              <Button
                className="btn-red margin-top-10 margin-bottom-10"
                href="/menu"
              >
                Order Now{" "}
              </Button>
            </Col>
            <col className="whitespace"></col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <div className="col-lg-4">
            <div className="about-info-left">
              <h2>Highlights from the Business</h2>
              <ul className="paddingSpecial">
                <li>Culinary Excellence</li>
                <li>Flavorful Innovation</li>
                <li>Warm and Inviting Atmosphere</li>
                <li>Professional Catering Services</li>
                <li>Sustainability and Quality</li>
                <li>Customer-Centric Approach</li>
                <li>Community Engagement</li>
                <li>Daily and Seasonal Specials</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="about-info-right">
              <Typography variant="h4">
                ABC Grill Restaurant & Catering - Our Commitment
              </Typography>
              <Typography variant="boby1">
                {" "}
                We commit to culinary excellence, customer satisfaction, and community engagement, making it a go-to destination for those seeking exceptional grilling and memorable dining experiences.{" "}
              </Typography>
              <br />
              <Typography variant="h4" className="whitespace">
                About the Business
              </Typography>
              <Typography variant="boby1">
                At ABC Grill Restaurant & Catering, our purpose is to kindle moments of culinary delight and connection. We are dedicated to igniting the passion for exceptional grilling and crafting unforgettable dining experiences. Through the art of the grill, we strive to create a warm and welcoming atmosphere where flavors come alive, and every bite is an invitation to savor life's finest moments. We commit to delivering a symphony of taste, a sense of togetherness, and a lasting, fiery joy to our valued patrons, making ABC Grill the go-to destination for those seeking not just a meal, but a memorable journey through the world of exceptional cuisine.
              </Typography>
              <div className="inner-about-gallery margin-top-30 whitespace">
                <div className="col-lg-12">
                  <div className="col-lg-3 col-md-3 float-left">
                    <img
                      width="100%"
                      height="100%"
                      title="ABC Grill Restaurant & Catering About Us Image1"
                      src={aboutimg1}
                      alt="ABC Grill Restaurant & Catering About Us Image1"
                      loading="lazy"
                    />
                  </div>
                  <div className="col-lg-3 col-md-3 float-left">
                    <img
                      width="100%"
                      height="100%"
                      title="ABC Grill Restaurant & Catering About Us Image2"
                      src={aboutimg2}
                      alt="ABC Grill Restaurant & Catering About Us Image2"
                      loading="lazy"
                    />
                  </div>
                  <div className="col-lg-3 col-md-3 float-left">
                    <img
                      width="100%"
                      height="100%"
                      title="ABC Grill Restaurant & Catering About Us Image3"
                      src={aboutimg3}
                      alt="ABC Grill Restaurant & Catering About Us Image3"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <col className="whitespace"></col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};
export default withRouter(About);