import "bootstrap/dist/css/bootstrap.min.css";
import { MenuNavigation } from "../eComm/components/MenuNavigation";
import { ShowCase } from "../eComm/components/pages/homepage/ShowCase";
import { DiscoverMenu } from "../eComm/components/pages/homepage/DiscoverMenu";
import { HomepageSlider } from "../eComm/components/pages/homepage/HomepageSlider";
import { Footer } from "../eComm/components/Footer";
import { useCookies } from "react-cookie";
import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { JsonLd } from "react-schemaorg";
import { Helmet, HelmetProvider } from "react-helmet-async";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@material-ui/core";

const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;

export const HomepageLanding = () => {
  const schema = {
    "@context": "https://schema.org",
    "@type": "Restaurant",
    name: "ABC Grill Restaurant & Catering",
    address: {
      "@type": "PostalAddress",
      streetAddress: "454 Hancock Ave",
      addressLocality: "South Elgin",
      postalCode: "60177",
      addressRegion: "IL",
      addressCountry: "US",
    },
    url: "https://abcbargrill.servingintel.com/",
    telephone: "+1 224-538-6669",
    priceRange: "$5-100",
    openingHoursSpecification: [
      {
        "@type": "OpeningHoursSpecification",
        dayOfWeek: ["Monday", "Tuesday", "Thursday", "Friday", "Saturday"],
        opens: "11:00",
        closes: "18:00",
      },
      {
        "@type": "OpeningHoursSpecification",
        dayOfWeek: ["Wednesday"],
        opens: "10:00",
        closes: "13:00",
      },
    ],
    geo: {
      "@type": "GeoCoordinates",
      latitude: "41.98470832584783",
      longitude: "-88.30558602176764",
    },
    image: ["https://abcbargrill.servingintel.com/logo512.png"],
  };

  const [openWelcome, setOpenWelcome] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [cookieSICName, setCookieSICName, removeCookieSICName] = useCookies([
    "sic_name",
  ]);

  const getCookie = (key) => {
    var keyValue = document.cookie.match("(^|;) ?" + key + "=([^;]*)(;|$)");
    return keyValue ? keyValue[2] : null;
  };

  const setCookie = (key, value) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + 365 * 24 * 60 * 60 * 1000);
    document.cookie =
      key + "=" + value + ";expires=" + expires.toUTCString() + ";path=/";
  };

  // useEffect(() => {
  //   var verifyOrderProcess = new EventSource(
  //     "https://ecommv2.servingintel.com/postpayment/verifyorderprocess/" +
  //     siteId +
  //     "/" +
  //     getCookie("orderId") +
  //     "?server_id=" +
  //     serverId
  //   );
  //   verifyOrderProcess.onmessage = function (event) {
  //     if (event.data === 1) {
  //       verifyOrderProcess.close();
  //       setCookie("orderId", "");
  //     }
  //   };
  // }, []);

  useEffect(() => {
    if (cookieSICName) {
      //setOpenWelcome(true);
    }
  }, []);

  const handleCloseWelcome = () => {
    setOpenWelcome(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Dialog
        open={openWelcome}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        expires={365}
        id="modalAlert"
      >
        <DialogTitle id="alert-dialog-title">{"Greetings!"}</DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{ "text-align": "left" }}
            id="alert-dialog-description"
          >
            <h5>
              AJ's Sliders is remodeling and rebranding for 4 weeks as AJ's Keto
              Factory.
            </h5>

            <h5>
              In the meantime, please visit AJ's Java Joint{" "}
              <a
                href="https://ajsjavajoint.com"
                className="f-link"
                target="_blank"
              >
                https://ajsjavajoint.com
              </a>{" "}
              in South Elgin to continue to enjoy nutritious coffee and
              delicious food. We'll be back in full force soon, so see you all
              there!
            </h5>

            <b>{cookieSICName.sic_name}</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ "text-align": "center", display: "block" }}>
          <Button onClick={handleCloseWelcome} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <div className="App">
        <MenuNavigation />
        <HomepageSlider />
        <ShowCase />
        <DiscoverMenu />
        <Footer />
      </div>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Where Every Bite Ignites Your Delight!</title>
          <meta
            name="keywords"
            content="Crafting Memorable Moments, One Grill at a Time - Join the ABC Grill Restaurant & Catering - Restaurant Family!"
          />
          <link rel="canonical" href="/" />
          <script type="application/ld+json">{JSON.stringify(schema)}</script>
        </Helmet>
      </HelmetProvider>
    </>
  );
};
