import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ModalVideo from "react-modal-video";
import emptyImg from "../../../assets/images/empty-img.png";
import Card from "@material-ui/core/Card";
import Box from "@mui/material/Box";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { numberWithCommas } from "../../../utils/numbersWithComma";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";
import axios from "axios";
import { FaPlus } from "react-icons/fa";
import { Stack } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import "react-modal-video/scss/modal-video.scss";

const siteImgSrc = process.env.REACT_APP_IMAGE_SRC;
const site = process.env.REACT_APP_SITE;
const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;
let enableItemVideo = process.env.REACT_APP_ITEM_VIDEO;

export default function FoodCard(props) {
  const isMobile = useMediaQuery("(max-width:600px)");

  let current_url = window.location.href;
  current_url = current_url.split("/");

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      margin: theme.spacing(2),
      height: "auto",
      position: "relative",
      padding: {
        sm: 0,
      },
      backgroundColor: "white",
      cursor: "pointer",
      borderBottom: isMobile && "2px solid #f2f2f2",
      border: !isMobile && "2px solid #f2f2f2",
      borderRadius: "8px",
    },
    details: {
      // display: "flex",
      // flexDirection: "column",
      width: isMobile ? "100%" : "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      webkitLineClamp: "3" /* number of lines to show */,
      webkitBoxOrient: "vertical",
    },
    price: {
      position: "absolute",
      bottom: "5px",
      color: "black",
    },
    content: {
      // flex: "0 1 auto",
      width: "100%",
      wordWrap: "break-word",
      overflow: "hidden",
      lineClamp: 2,
    },
    cover: {
      width: isMobile ? "250px" : "200px",
      position: "relative",
    },
    controls: {
      display: "flex",
      alignItems: "center",
      paddingLeft: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    playIcon: {
      height: 50,
      width: 50,
    },
  }));

  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();
  const [isItemAvailable, setIsItemAvailable] = useState(true);

  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <Card
        sx={{ display: "flex" }}
        className={classes.root + " bg-white"}
        square
        elevation={0}
      >
        <Box
          sx={{ display: "flex", flexDirection: "column" }}
          onClick={() => {
            props.getSelectedProduct(props.product.ItemID);
            history.push(
              match.params.category
                ? `/menu/${
                    match.params.category
                  }/${props.product.ItemName.replace(/[^a-zA-Z0-9]+/g, "-")}`
                : `/menu/${props.product.ItemName.replace(
                    /[^a-zA-Z0-9]+/g,
                    "-"
                  )}`
            );
          }}
          className={classes.content}
        >
          <CardContent
            sx={{ flex: "1 0 auto", height: "100%" }}
            justifyContent="space-between"
          >
            <div>
              <Typography
                component="div"
                variant="h5"
                style={{
                  fontWeight: 500,
                  lineHeight: 1.2,
                }}
              >
                {props.product.ItemName}
              </Typography>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
                style={{
                  // textOverflow: "ellipsis",
                  // whiteSpace: "nowrap",
                  display: "-webkit-box",
                  "-webkit-line-clamp": "4",
                  overflow: "hidden",
                  "-webkit-box-orient": "vertical",
                  lineHeight: 1.2,
                }}
              >
                {props.product.ItemDescription
                  ? isMobile
                    ? props.product.ItemDescription.length > 50
                      ? props.product.ItemDescription.substring(0, 50 - 3) +
                        "..."
                      : props.product.ItemDescription.substring(0, 50)
                    : props.product.ItemDescription.length > 70
                    ? props.product.ItemDescription.substring(0, 70 - 3) + "..."
                    : props.product.ItemDescription.substring(0, 70)
                  : null}
              </Typography>
            </div>
            <div
              style={{
                position: "absolute",
                bottom: "15px",
                left: "15px",
                display: "grid",
                placeItems: "center",
                borderRadius: "8px",
                border: "1px solid white",
                zIndex: 1,
              }}
            >
              <Typography component="h4" variant="h5">
                $ {numberWithCommas(props.product.DefaultPrice / 100)}
              </Typography>
            </div>
          </CardContent>
        </Box>
        <CardMedia className={classes.cover} title={props.product.ItemName}>
          <img
            onClick={() => {
              props.getSelectedProduct(props.product.ItemID);
              history.push(
                match.params.category
                  ? `/menu/${
                      match.params.category
                    }/${props.product.ItemName.replace(/[^a-zA-Z0-9]+/g, "-")}`
                  : `/menu/${props.product.ItemName.replace(
                      /[^a-zA-Z0-9]+/g,
                      "-"
                    )}`
              );
            }}
            src={
              props.product.FileName && props.product.FileName.length > 0
                ? siteImgSrc + encodeURIComponent(props.product.FileName)
                : emptyImg
            }
            style={{
              borderRadius: "4px",
            }}
            onError={(error) => {
              //replacement of broken Image
              error.target.src = emptyImg;
            }}
          />
          <Box sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}>
            <ModalVideo
              channel="youtube"
              youtube={{ mute: 0, autoplay: 0, controls: 1 }}
              isOpen={isOpen}
              videoId="0B1pBhtI6vA"
              onClose={() => setOpen(false)}
            />
            {enableItemVideo == 1 ? (
              <IconButton
                aria-label="play/pause"
                style={{
                  background: "#cc3333",
                  color: "white",
                  position: "absolute",
                  top: "2px",
                  right: "2px",
                  display: "grid",
                  placeItems: "center",
                  borderRadius: "8px",
                  border: "1px solid white",
                  zIndex: 1,
                }}
                onClick={() => setOpen(true)}
              >
                <PlayArrowIcon sx={{ height: 9, width: 9 }} />
              </IconButton>
            ) : null}
            <IconButton
              aria-label="add"
              style={{
                background: "#cc3333",
                color: "white",
                position: "absolute",
                bottom: "2px",
                right: "2px",
                display: "grid",
                placeItems: "center",
                borderRadius: "8px",
                border: "1px solid white",
                zIndex: 1,
              }}
              onClick={() => {
                props.getSelectedProduct(props.product.ItemID);
                history.push(
                  match.params.category
                    ? `/menu/${
                        match.params.category
                      }/${props.product.ItemName.replace(
                        /[^a-zA-Z0-9]+/g,
                        "-"
                      )}`
                    : `/menu/${props.product.ItemName.replace(
                        /[^a-zA-Z0-9]+/g,
                        "-"
                      )}`
                );
              }}
            >
              <FaPlus fontSize=".5rem" />
            </IconButton>
          </Box>
        </CardMedia>
      </Card>
    </>
  );
}
