import "../../../App.css";
import { Container, Row, Col, h5, p, h2, div } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "../../../assets/images/logo.png";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { withRouter, useHistory } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AccountForm from "./components/AccountForm";
import MobileForm from "./components/MobileForm";
import PersonalDetails from "./components/PersonalDetails";
import siLogo from "../../../assets/images/servingintel-logo-bw.svg";
import CookieIcon from "../../../assets/images/cookie-icon.svg";
import { FaHeart } from "react-icons/fa";
import axios from "axios";
import Swal from "sweetalert2";
import { Helmet, HelmetProvider } from "react-helmet-async";
import CookieConsent from "react-cookie-consent";

const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;
const storeId = process.env.REACT_APP_STORE_ID;

export const Register = () => {
  function Copyright(props) {
    return (
      <Typography variant="body2" color="white" align="center" {...props}>
        Made with <FaHeart style={{ color: "red" }} /> by <br />
        <Link color="inherit" href="https://servingintel.com">
          <img
            className="Logo"
            style={{ padding: "10px 0", width: "150px" }}
            src={siLogo}
            alt="ServingIntel Logo"
            width="100%"
            height="100%"
            title="ServingIntel Logo"
            loading="lazy"
          />
        </Link>
      </Typography>
    );
  }

  const steps = ["Account", "Mobile", "Personal Details"];
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [cemail, setCEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [DOB, setDOB] = useState("");
  const [anniv, setAnniv] = useState("");

  const history = useHistory();

  const getFirstName = (val) => {
    setFirstName(val);
  };
  const getLastName = (val) => {
    setLastName(val);
  };
  const getEmail = (val) => {
    setEmail(val);
  };
  const getCEmail = (val) => {
    setCEmail(val);
  };
  const getMobile = (val) => {
    setMobile(val);
  };
  const getPassword = (val) => {
    setPassword(val);
  };
  const getCPassword = (val) => {
    setCPassword(val);
  };
  const getDOB = (val) => {
    setDOB(val);
  };
  const getAnniv = (val) => {
    setAnniv(val);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <AccountForm
            getFirstName={getFirstName.bind(this)}
            firstName={firstName}
            getLastName={getLastName.bind(this)}
            lastName={lastName}
            getEmail={getEmail.bind(this)}
            email={email}
            getCEmail={getCEmail.bind(this)}
            cemail={cemail}
          />
        );
      case 1:
        return (
          <MobileForm
            getMobile={getMobile.bind(this)}
            mobile={mobile}
            getPassword={getPassword.bind(this)}
            password={password}
            getCPassword={getCPassword.bind(this)}
            cpassword={cpassword}
          />
        );
      case 2:
        return (
          <PersonalDetails
            getDOB={getDOB.bind(this)}
            DOB={DOB}
            getAnniv={getAnniv.bind(this)}
            anniv={anniv}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  }

  const theme = createTheme();
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    if (activeStep == 0 && email && cemail && email != cemail) {
      Swal.fire({
        title: "Email Confirmation",
        text: "Email address did not match.",
        icon: "info",
        confirmButtonText: "OK",
      });
    } else if (activeStep == 1 && password != cpassword) {
      Swal.fire({
        title: "Password Confirmation",
        text: "Password did not match.",
        icon: "info",
        confirmButtonText: "OK",
      });
    } else if (!firstName || !lastName || !email || !cemail) {
      Swal.fire({
        title: "Invalid Input",
        text: "Fields marked with * are required",
        icon: "Info",
        confirmButtonText: "OK",
      });
    } else if (activeStep == 1 && (!password || !cpassword || !mobile)) {
      Swal.fire({
        title: "Invalid Input",
        text: "Fields marked with * are required",
        icon: "Info",
        confirmButtonText: "OK",
      });
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const submit = () => {
    const url =
      "https://ecommv2.servingintel.com/customer/register-eloyalty/" +
      serverId +
      "/" +
      siteId +
      "/" +
      storeId;
    axios
      .post(url, {
        firstName: firstName,
        lastName: lastName,
        email: email,
        mobile: mobile,
        password: password,
        DOB: DOB,
        anniv: anniv,
      })
      .then((response) => {
        if (response.data > 0) {
          handleNext();
        } else {
          if (response.data == -1) {
            Swal.fire({
              title: "Registration Failed!",
              text: "This email is already registered. Please use another email.",
              icon: "info",
              confirmButtonText: "OK",
            });
          } else {
            Swal.fire({
              title: "Registration Failed!",
              text: "Fields marked with * are required",
              icon: "warning",
              confirmButtonText: "OK",
            });
          }
        }
      })
      .catch((error) => { });
  };

  useEffect(() => {
    // document.title = "Register";
    ReactGA.initialize("UA-172332025-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  const paperStyle = {
    padding: 20,
    height: "auto",
    width: 320,
    margin: "20px auto",
  };
  const avatarStyle = { backgroundColor: "#1bbd7e" };
  const btnstyle = { margin: "4px 0" };

  return (
    <div className="customBg">
      <ThemeProvider theme={theme}>
        <HelmetProvider>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Register: eLoyalty</title>
            <link rel="canonical" href="/register" />
            <meta
              name="description"
              content="Simply sign up for the program and start getting rewards right away."
            />
          </Helmet>
        </HelmetProvider>
        <Container
          component="main"
          className="eLoyaltyBg"
          maxWidth="md"
          style={{ maxWidth: "850px", padding: "0", marginTop: "-50px" }}
        >
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "5px 20px",
              borderRadius: "5px",
            }}
          >
            <a href="/" target="_self">
              <img
                className="Logo title"
                style={{ padding: "10px 0", width: "150px" }}
                src={Logo}
                width="100%"
                height="100%"
                title="ABC Grill Restaurant & Catering Logo"
                alt="ABC Grill Restaurant & Catering Logo"
                loading="lazy"
              />
            </a>

            <Typography
              component="h1"
              className="white"
              variant="h4"
              style={{ marginBottom: "10px", fontWeight: "550" }}
            >
              Join our ABC Club
            </Typography>
            <div className="col-lg-12">
              <div className="white">
                <h3>Benefits include:</h3>
                <ul className="paddingSpecial">
                  <li>receiving $5 in ABC Bucks for every $50 in purchases.</li>
                  <li>$10 in ABC Bucks for activating your account,</li>
                  <li>and emails with member-only specials and events!</li>
                </ul>
              </div>
            </div>
            <Paper
              style={{
                boxShadow: "none",
                marginTop: "0",
                backgroundColor: "transparent",
                marginBottom: "1rem",
                width: "100%",
              }}
            >
              <Stepper
                className="activeStep"
                activeStep={activeStep}
                // sx={{ pt: 2, pb: 3 }}
                style={{
                  background: "beige",
                  padding: "1.25rem 0",
                  borderRadius: "5px",
                }}
              >
                {steps.map((label) => (
                  <Step
                    onKeyUp={(e) => {
                      console.log(e);
                    }}
                    key={label}
                  >
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <React.Fragment>
                {activeStep === steps.length ? (
                  <React.Fragment>
                    <Paper
                      className="formBG"
                      style={{
                        boxShadow: "none",
                        marginTop: "0",
                        marginBottom: "1rem",
                        background: "beige",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          margin: "1.25rem 0",
                        }}
                        variant="outlined"
                      >
                        <Typography
                          variant="contained"
                          style={{ margin: "1.25rem 0" }}
                        >
                          {
                            "Your account has been created and an activation link has been sent to the email address you entered. Note that you must activate the account by selecting the activation link when you get the email before you can login."
                          }
                        </Typography>
                      </Box>
                    </Paper>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {getStepContent(activeStep)}
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      {activeStep !== 0 && (
                        <Grid container spacing={0}>
                          <Button
                            variant="contained"
                            onClick={handleBack}
                            fullWidth
                            sx={{
                              marginRight: "5px !important",
                              mt: 3,
                              fontWeight: "550",
                            }}
                          >
                            Previous
                          </Button>
                        </Grid>
                      )}
                      <Grid container spacing={0}>
                        <Button
                          variant="contained"
                          fullWidth
                          onClick={() => {
                            if (activeStep === steps.length - 1) {
                              submit();
                            } else {
                              handleNext();
                            }
                          }}
                          sx={{ mt: 3, ml: 1, fontWeight: "550" }}
                        >
                          {activeStep === steps.length - 1 ? "Submit" : "Next"}
                        </Button>
                      </Grid>
                    </Box>

                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        onClick={() => {
                          history.push("/login");
                        }}
                        size="large"
                        variant="contained"
                        style={{
                          fontWeight: "550",
                          backgroundColor: "#8c959f",
                          borderColor: "none",
                          margin: ".5rem 0",
                        }}
                      >
                        Login if you already have account
                      </Button>
                    </Grid>
                  </React.Fragment>
                )}
              </React.Fragment>
            </Paper>

            <Grid container spacing={1}>
              {activeStep != 3 ? (
                <React.Fragment>
                  {/* <Grid item xs={6}>
                    <Button
                      fullWidth
                      // onClick={formik.handleSubmit}
                      size="large"
                      variant="contained"
                      style={{ fontWeight: "550" }}
                    >
                      Register
                    </Button>
                  </Grid> */}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      onClick={() => history.push("/login")}
                      size="large"
                      variant="contained"
                      style={{
                        fontWeight: "550",
                        borderColor: "none",
                        marginBottom: "1rem",
                      }}
                    >
                      Login your verified account
                    </Button>
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
          </Box>
          <Copyright />
        </Container>
        <CookieConsent
          location="bottom"
          buttonText="Accept all cookies"
          cookieName="myAwesomeCookieName2"
          buttonClasses="btn btn-red"
          style={{ background: "#000" }}
          buttonStyle={{ fontSize: "16px" }}
          expires={365}
        >
          <div className="center">
            <Col xs={3} className="padding-bottom-20 center">
              <img
                width="100%"
                height="100%"
                title="Accept all cookies Icon"
                src={CookieIcon}
                size="15"
                alt="Accept all cookies Icon"
                loading="lazy"
              />
            </Col>
            <p>
              <h3 className="center"> Your Privacy </h3>
            </p>
          </div>

          <Typography>
            Greetings! Our website uses cookies so we may better serve you. By
            clicking “Accept all cookies” and by continuing to browse our site you
            are agreeing to our{" "}
          </Typography>
          <Typography>
            <a
              href="#"
              onClick={() => {
                history.push("/terms-and-conditions");
              }}
            >
              Terms and Conditions
            </a>
          </Typography>
        </CookieConsent>
      </ThemeProvider>
    </div>
  );
};

export default withRouter(Register);
