import showcaseimg from "../../../../../assets/images/about-img-left.png";
import showcaseimg1 from "../../../../../assets/images/showcase-icon.png";
import "../../../../../App.css";
import {
  Container,
  Row,
  Col,
  Button,
  h1,
  h2,
  p,
  h3,
  hr,
} from "react-bootstrap";

export const ShowCase = () => {
  return (
    <Container className="showcase">
      <Row>
        <Col xs={6} md={6}>
          <img
            width="100%"
            height="100%"
            title="ABC Grill Restaurant & Catering Showcase Image Left"
            className="img-sm"
            src={showcaseimg}
            alt="ABC Grill Restaurant & Catering Showcase Image Left"
            loading="lazy"
          />
        </Col>
        <Col xs={6} md={5}>
          <div className="left margin-top-20">
            <h1>
              <em>ABC Grill Restaurant & Catering</em>
            </h1>
            <h2 className="margin-top-20">Get to know us</h2>
          </div>
          <div className="left margin-top-20">
            <p>
              <strong>ABC Grill Restaurant & Catering</strong> invites you to a <em>"Fire & Feast Fiesta,"</em> where every bite is a delight! We promise a culinary adventure that turns each dish into a work of art, igniting your taste buds and leaving you with a fiery, unforgettable experience.
            </p>
          </div>
          <div className="showcase-right-text left">
            <Col xs={6} md={3} className="img-icon">
              <img
                width="100%"
                height="100%"
                title="ABC Grill Restaurant & Catering Showcase Icon"
                src={showcaseimg1}
                size="25"
                alt="ABC Grill Restaurant & Catering Showcase Icon"
                loading="lazy"
              />
            </Col>
            <p>
              <h3 className="white"> Discover Menu </h3>
              <span className="white">
                Come savor the flavors that make every meal a feast worth celebrating.
              </span>
              <br></br>
              <span>
                <Button
                  className="btn-red margin-top-10 margin-bottom-10"
                  href="/menu"
                >
                  Order Now{" "}
                </Button>
              </span>
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
