import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

export default function DirectionCard() {
  return (
    <Card>
      <a aria-label="Google Map" href="https://maps.app.goo.gl/NMM1nvELUs59KjYW7" className="none-link" target="_blank" ><CardActionArea className="color-scheme" >
        <CardMedia
          component="img"
          height="140"
          image="/static/get-direction.png"
          width="100%"
          alt="Get Direction"
          loading="lazy"
          title="ABC Grill Restaurant & Catering Get Direction"
        />
        <CardContent>
          <Typography gutterBottom variant="h4" component="div">
            Get Direction
          </Typography>
          <Typography gutterBottom variant="h6" component="div">
            454 Hancock Ave,
            <br></br>
            South Elgin, IL 60177, USA
          </Typography>
        </CardContent>
      </CardActionArea></a>
    </Card>
  );
}