import * as React from 'react';
import "../../../App.css";
import {
	Container,
	Row,
	Col,
	Button,
	h5,
	p,
	h2,
	div
} from "react-bootstrap";
import {
	FontAwesomeIcon
} from "@fortawesome/react-fontawesome";
import room412 from "../../../assets/images/room412.png";
import room414 from "../../../assets/images/room414.png";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
export default function RoomsContent() {
	return ( < Container > < Row > < div className = "col-lg-12" > < Col xs = {
			12
		}
		className = "center whitespace" > < h2 className = " dark" > Private Event Room < /h2> < img src = {
			room412
		}
		/> < /Col> < /div> < div className = "col-lg-12" > < Col xs = {
			12
		}
		className = " center whitespace" > < h2 className = " dark " > Inquire to book your next event</h2> < img src = {
			room414
		}
		/> < /Col> < /div> < /Row> < /Container>      );
}