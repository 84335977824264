import "../../../App.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import Typography from "@material-ui/core/Typography";

export const ContactSlider = () => {
  return (
    <div className="ContactSlider">
      <div className="ContactHeader">
        <Container>
          <Row>
            <col className="whitespace"></col>
            <Col xs={12} lg={10}>
              <Typography className="white">
                {" "}
                Service Options: Dine-in | Curbside pickup | Delivery
              </Typography>
              <h1 className="left gold blankspace shadow">
                Culinary excellence,
                <br></br>
                Offering a diverse menu
              </h1>

              <Typography className="white blankspace">
                {" "}
                Showcases expertly grilled dishes, from succulent steaks to mouthwatering BBQ specialties!
              </Typography>
              <Typography className="white blankspace">
                {" "}
                454 Hancock Ave, South Elgin, IL 60177, USA
              </Typography>
              <Button
                className="btn-red whitespace margin-bottom-10"
                href="/register"
              >
                Join our ABC Club Program{" "}
              </Button>
            </Col>
            {/* <Col xs={12} lg={5} className="PhonerightImg"></Col> */}
            <col className="whitespace"></col>
          </Row>
        </Container>
      </div>
    </div>
  );
};