/*import "../../../../../App.css";*/
import "../../../../../assets/css/custom.css";
import burgers from "../../../../../assets/images/burgers.jpg";
import grilledsteaks from "../../../../../assets/images/grilled-steaks.jpg";
import seafood from "../../../../../assets/images/seafood.jpg";
import ketomeals from "../../../../../assets/images/keto-meals.png";
import comingsoon05 from "../../../../../assets/images/coming-soon.jpg";
import comingsoon06 from "../../../../../assets/images/coming-soon.jpg";
import comingsoon07 from "../../../../../assets/images/coming-soon.jpg";
import comingsoon08 from "../../../../../assets/images/coming-soon.jpg";
import comingsoon09 from "../../../../../assets/images/coming-soon.jpg";
import menu from "../../../../../assets/images/coming-soon.jpg";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardGroup,
  Cardcomingsoon,
  h3,
  p,
  h2,
  ListGroup,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const buttonColor = process.env.REACT_APP_BUTTON_COLOR;

const images = [
  {
    url: grilledsteaks,
    title: "Grilled Steaks",
    width: "33.333%",
  },
  {
    url: burgers,
    title: "Burgers and Sandwiches",
    width: "33.333%",
  },
  {
    url: seafood,
    title: "Seafood",
    width: "33.333%",
  },
  // {
  //   url: twominmeal,
  //   title: "2 Minute Meals",
  //   width: "33.333%",
  // },
  // {
  //   url: comingsoon06,
  //   title: "Coming Soon",
  //   width: "33.333%",
  // },
  // {
  //   url: comingsoon07,
  //   title: "Coming Soon",
  //   width: "33.333%",
  // },
  // {
  //   url: comingsoon08,
  //   title: "Coming Soon",
  //   width: "33.333%",
  // },
  // {
  //   url: comingsoon09,
  //   title: "Coming Soon",
  //   width: "33.333%",
  // },
  // {
  //   url: comingsoon05,
  //   title: "Coming Soon",
  //   width: "33.333%",
  // },
];

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: "relative",
  height: 400,
  [theme.breakpoints.down("sm")]: {
    width: "100% !important", // Overrides inline-style
    height: 200,
  },
  "&:hover, &.Mui-focusVisible": {
    zIndex: 1,
    "& .MuiImageBackdrop-root": {
      opacity: 0.15,
    },
    "& .MuiImageMarked-root": {
      opacity: 0,
    },
    "& .MuiTypography-root": {
      border: "0px transparent currentColor",
    },
  },
}));

const ImageSrc = styled("span")({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: "cover",
  backgroundPosition: "center 33.333%",
});

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.common.white,
}));

const ImageBackdrop = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.4,
  transition: theme.transitions.create("opacity"),
}));

const ImageMarked = styled("span")(({ theme }) => ({
  height: 3,
  width: 18,
  backgroundColor: theme.palette.common.white,
  position: "absolute",
  bottom: -2,
  left: "calc(50% - 9px)",
  transition: theme.transitions.create("opacity"),
}));

export const DiscoverMenu = () => {
  return (
    <div className="MainContainerImg whitespace">
      <Container>
        <Row>
          <col className="whitespace"></col>
          <Col xs={12}>
            <h2 className="center white">Explore Our Menu</h2>
          </Col>
          <h3 className="whitespace center gold">
            Come savor the flavors that make every meal a feast worth celebrating.
          </h3>
          <col className="whitespace"></col>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              minWidth: 250,
              width: "100%",
            }}
            className="gallery"
          >
            {images.map((image) => (
              <ImageButton
                focusRipple
                key={image.title}
                style={{
                  width: image.width,
                  cursor: "pointer",
                }}
              >
                <ImageSrc style={{ backgroundImage: `url(${image.url})` }} />
                <ImageBackdrop className="MuiImageBackdrop-root" />
                <Image>
                  <Button href="/menu"
                    style={{
                      backgroundColor: buttonColor,
                      cursor: "pointer",
                    }}
                  >
                    <p
                      component="span"
                      variant="subtitle1"
                      color="inherit"
                      style={{
                        position: "relative",
                        margin: ".5rem",
                      }}
                    >
                      {image.title}
                      <ImageMarked className="MuiImageMarked-root" />
                    </p>
                  </Button>
                </Image>
              </ImageButton>
            ))}
          </Box>
        </Row>
      </Container>
    </div>
  );
};