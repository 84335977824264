import { Container, Row, Col, Button, h5, p, h2, div } from "react-bootstrap";
// import "../../../../../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import center from '../../../assets/css/custom.css';
import { MenuNavigation } from '../components/MenuNavigation';
import { Footer } from '../components/Footer';
import React, { useEffect } from 'react'

export const PrivacyPolicy = () => {

  useEffect(() => {
    document.title = "Privacy Policy"
  }, [])

  return (
    <div>
      <MenuNavigation />
      <div className="HeaderImg">
        <Container>
          <Row>
            <col className="whitespace"></col>
            <Col xs={12}>
              <h2 className="center gold shadow">Privacy</h2>
            </Col>
            <Col xs={10} className="center">
              <h5 className="white"></h5>
            </Col>

            <col className="whitespace"></col>
          </Row>
        </Container>
      </div>

      <Container className="privacy">
        <Row className="left">
          <Col xs={12}>
            <h3 className="whitespace">Privacy Policy</h3>
            <p>
              Protecting your private information is our priority. This Statement of Privacy applies to <a href="https://abcbargrill.servingintel.com" className="f-link" target="_blank">https://abcbargrill.servingintel.com</a> and ABC Grill Restaurant & Catering and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to ABC Grill Restaurant & Catering include <a href="https://abcbargrill.servingintel.com" className="f-link" target="_blank">https://abcbargrill.servingintel.com</a> and ABC Grill Restaurant & Catering. The ABC Grill Restaurant & Catering website is a ecommerce site. By using the ABC Grill Restaurant & Catering website, you consent to the data practices described in this statement.
            </p>
            <h3>Collection of your Personal Information</h3>
            <p>
              In order to better provide you with products and services offered on our Site, ABC Grill Restaurant & Catering may collect personally identifiable information, such as your:
            </p>

            <ul className="paddingSpecial">
              <li>First and Last Name</li>
              <li>Mailing Address</li>
              <li>Email Address</li>
              <li>Phone Number</li>
            </ul>
            <p>
              We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required to provide certain personal information to us when you elect to use certain products or services available on the Site. These may include: (a) registering for an account on our Site; (b) entering a sweepstakes or contest sponsored by us or one of our partners; (c) signing up for special offers from selected third parties; (d)
              sending us an email message; (e) submitting your credit card or other payment information when ordering and purchasing products and services on our Site. To wit, we will use your information for, but not limited to, communicating with you in relation to services and/or products you have requested from us. We also may gather additional personal or non-personal information in the future.
            </p>
            <h3>Sharing Information with Third Parties</h3>
            <p>
              ABC Grill Restaurant & Catering does not sell, rent or lease its customer lists to third parties.
            </p>
            <p>
              ABC Grill Restaurant & Catering may, from time to time, contact you on behalf of external business partners about a particular offering that may be of interest to you. In those cases, your unique personally identifiable information (e-mail, name, address, telephone number) is not transferred to the third party. ABC Grill Restaurant & Catering may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to ABC Grill Restaurant & Catering, and they are required to maintain the confidentiality of your information.
            </p>
            <p>
              ABC Grill Restaurant & Catering may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on ABC Grill Restaurant & Catering or the site; (b) protect and defend the rights or property of ABC Grill Restaurant & Catering; and/or (c) act under exigent circumstances to protect the personal safety of users of ABC Grill Restaurant & Catering, or the public.
            </p>

            <h3>Tracking User Behavior</h3>
            <p>
              ABC Grill Restaurant & Catering may keep track of the websites and pages our users visit within ABC Grill Restaurant & Catering, in order to determine what ABC Grill Restaurant & Catering services are the most popular. This data is used to deliver customized content and advertising within ABC Grill Restaurant & Catering to customers whose behavior indicates that they are interested in a
              particular subject area.
            </p>
            <h3>Automatically Collected Information</h3>
            <p>
              Information about your computer hardware and software may be automatically collected by ABC Grill Restaurant & Catering. This information can include: your IP address, browser type, domain names, access times and referring website addresses. This information is used for the operation of the service, to maintain quality of the service, and
              to provide general statistics regarding use of the ABC Grill Restaurant & Catering website.
            </p>
            <h3>Use of Cookies</h3>
            <p>
              The ABC Grill Restaurant & Catering website may use "cookies" to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you. One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the Web server that you have returned to a specific page. For example, if you personalize ABC Grill Restaurant & Catering pages, or register with ABC Grill Restaurant & Catering site or services, a cookie helps ABC Grill Restaurant & Catering to recall your
              specific information on subsequent visits. This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on. When you return to the same ABC Grill Restaurant & Catering website, the information you previously provided can be retrieved, so you can easily use the ABC Grill Restaurant & Catering features that you customized.
            </p>
            <p>
              You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the ABC Grill Restaurant & Catering services or websites you visit.
            </p>
            <h3>Security of your Personal Information</h3>
            <p>
              ABC Grill Restaurant & Catering secures your personal information from unauthorized access, use, or disclosure. ABC Grill Restaurant & Catering uses the following methods for this purpose:
            </p>
            <h5>~Security of your Personal Information</h5>
            <p>
              When personal information (such as a credit card number) is transmitted to other websites, it is protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol.
            </p>
            <p>
              We strive to take appropriate security measures to protect against unauthorized access to or alteration of your personal information. Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, you acknowledge that: (a) there are security and privacy limitations inherent to the Internet which are beyond our control; and (b)
              security, integrity, and privacy of any and all information and data exchanged between you and us through this Site cannot be guaranteed.
            </p>
            <h3>Children Under Thirteen</h3>
            <p>
              ABC Grill Restaurant & Catering does not knowingly collect personally identifiable information from children under the age of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use this website.
            </p>
            <h3>E-mail Communications</h3>
            <p>
              From time to time, ABC Grill Restaurant & Catering may contact you via email for the purpose of providing announcements, promotional offers, alerts, confirmations, surveys, and/or other general communication.
            </p>
            <p>
              If you would like to stop receiving marketing or promotional communications via email from ABC Grill Restaurant & Catering, you may opt out of such communications by clicking on an UNSUBSCRIBE button.
            </p>
            <h3>Changes to this Statement</h3>
            <p>
              ABC Grill Restaurant & Catering reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account, by placing a prominent notice on our site, and/or by updating any privacy information on this page. Your continued use of the Site and/or Services available through this Site after such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy.
            </p>
            <h3>Contact Information</h3>
            <p>
              ABC Grill Restaurant & Catering welcomes your questions or comments regarding this Statement of Privacy. If you believe that ABC Grill Restaurant & Catering has not adhered to this Statement, please contact ABC Grill Restaurant & Catering at:
            </p>
            <p>
              <a href="https://maps.app.goo.gl/NMM1nvELUs59KjYW7" className="f-link" target="_blank">454 Hancock Ave, South Elgin, IL 60177, USA {" "}</a>
              <br />
              Telephone number: <a href="tel:+1 224-538-6669">+1 224-538-6669 </a> <br />
              Email Address: <a href="mailto:demohospitality@servingintel.com">demohospitality@servingintel.com</a>
            </p>
          </Col>
          <col className="whitespace"></col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};
